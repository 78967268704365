import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  CalendarOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
} from "antd";

import React, { useState } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";
import { downloadTicketPdf } from "../../helpers/TicketUtils";
import { Link, useHistory } from "react-router-dom";

const TicketSidebar = ({
  ticketData,
  ticketSearchParams,
  type,
  onCancelTicket,
  onRescheduleTicket = () => { },
}) => {
  const { user } = useAuthContext();
  const [smsForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  let history = useHistory();

  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailModalVisible((prev) => !prev);
  };

  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const loadpassengerData = (type) => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });
          else emailForm.setFieldsValue({ Email: data.Email });
        }
      });
    }
  };

  // const sendFlightSMSETicket = (val) => {
  //   ApiClient.post("sms/AirTicketsms", val).then((res) => {
  //     if (res.status === 200) {
  //       message.success("Sms Sent Successfully");
  //       setSmsModalVisible(false);
  //     } else {
  //       message.error("Something Went Wrong");
  //     }
  //   });
  // };
  const sendEmailSmsETicket = (val) => {
    // console.log(val,"val123");
    ApiClient.post("CommonUtility/notification", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  // const sendFlightETicket = (val) => {
  //   ApiClient.post("email/AirTicket", val).then((res) => {
  //     if (res.status === 200) {
  //       message.success("Email Sent Successfully");

  //       setEmailModalVisible(false);
  //     } else {
  //       message.error("Something Went Wrong");
  //     }
  //   });
  // };
  // const sendBusETicket = (val) => {
  //   ApiClient.post("email/busticket", val).then((res) => {
  //     if (res.status === 200) {
  //       message.success("Email Sent Successfully");

  //       setEmailModalVisible(false);
  //     } else {
  //       message.error("Something Went Wrong");
  //     }
  //   });
  // };

  const submitEmailForm = (val) => {
    // console.log(val, type, ticketData,"famil");
    if (limitCount.emailCount > 3) {
      message.error("Limit Exceeded");
      setEmailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }
    if (type === "Bus") {
      let formData = {
        // phoneNumber: "string",
        // travelType: 3,
        // trigger: 1,
        bookingReference: ticketData.referenceNumber,
        email: val.Email,
        pnrRef: ticketData.pnr,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: "string",
        travelType: 1,
        trigger: 1,
        bookingReference: ticketData.referenceNumber,
        email: val.Email,
      };

      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: "string",
        travelType: 2,
        trigger: 1,
        bookingReference: ticketData.RefNumber,
        email: val.Email,
      };
      sendEmailSmsETicket(formData);
    }

    // if (type === "Activities") {
    //   let formData = {
    //     phoneNumber: "string",
    //     travelType: 4,
    //     trigger: 1,
    //     bookingReference: ticketData?.TourDetails?.[0].referenceNumber,
    //     email: val.Email,
    //   };
    //   sendEmailSmsETicket(formData);
    // }
    // if (type === "BuidPackage") {
    //   let formData = {
    //     phoneNumber: "string",
    //     travelType: 5,
    //     trigger: 1,
    //     bookingReference: ticketData.RefNumber,
    //     email: val.Email,
    //   };
    //   sendEmailSmsETicket(formData);
    // }
  };

  const sendSms = (val) => {
    if (limitCount.smsCount > 3) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 3,
        trigger: 2,
        bookingReference: ticketData.bookingRefNo,
        email: "string",
      };
      sendEmailSmsETicket(formData);
    }
    // if (type === "Flight") {
    //   let formData = {
    //     phoneNumber: val.Mobile,
    //     travelType: 1,
    //     trigger: 2,
    //     bookingReference: ticketData.id,
    //     email: "string",
    //   };
    //   sendEmailSmsETicket(formData);
    // }
    // if (type === "Hotel") {
    //   let formData = {
    //     phoneNumber: val.Mobile,
    //     travelType: 2,
    //     trigger: 2,
    //     bookingReference: ticketData.RefNumber,
    //     email: "string",
    //   };
    //   sendEmailSmsETicket(formData);
    // }
  };

  const printTicket = () => {
    window.print();
  };

  const quickHelp = () => {

    history.push(`/admin/siteadmins-quickhelp?RefNo=${ticketSearchParams?.refNo ?? ''}`);
  }

  const BusCancellationQuickHelp = () => {

    // ApiClient.get("query/getquery")
    //   .then((res) => {
    //     if (res.isSuccess) {
    //       const detailsQuery = res.data.filter(q => q.queryName.toUpperCase().includes("BUS CANCELLATION ISSUE"))
    //       const queryId = detailsQuery[0]?.id ?? 0

    //       history.push(`/admin/siteadmins-quickhelp?RefNo=${ticketSearchParams?.tktNo ?? ''}&&queryId=${queryId}`);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    history.push(`/admin/siteadmins-quickhelp?RefNo=${ticketSearchParams?.refNo ?? ''}&&isBusCancellationReq=${true}`);


  }

  return (
    <div className="actionable-buttons">
      <div className="ticket-options">
        <p onClick={() => printTicket()}>
          <PrinterOutlined /> Print E-Ticket
        </p>
      </div>
      <div className="ticket-options">
        <p onClick={() => toggleEmailModal()}>
          <MailOutlined /> Email E-Ticket
        </p>
      </div>
      {ticketData.cancellable && (
        <div className="ticket-options">
          <p onClick={() => toggleSmsModal()}>
            <MessageOutlined /> Sms E-Ticket
          </p>
        </div>
      )}
      {ticketData.status != "BLOCK_FAILED" && ticketData.status != "CANCELLED" && ticketData.status != "BUSCANCELLEDANDREFUND" && ticketData.status != "BUSCANCELLED" && (
        <>
          <div className="ticket-options">
            <p onClick={() => onCancelTicket()}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
          <div className="ticket-options">
            <p onClick={() => BusCancellationQuickHelp()}>
              <i style={{ marginRight: "5%" }} class="fa fa-bus"></i> Bus Cancelled
            </p>
          </div>
        </>
      )}


      <div className="ticket-options">
        <p onClick={() => quickHelp()}>
          <QuestionCircleOutlined />Quick Help
        </p>
      </div>


      {/* {type === "Flight"
        ? ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel("Flight")}>
                <FileExcelOutlined /> Cancel / Reschedule E-Ticket
              </p>
            </div>
          )
        : null}

      {type === "Hotel"
        ? ticketData.BookingStatus != 3 &&
          ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
        : null}
      {type === "Activities" && (
        <div className="ticket-options">
          <p onClick={() => handleCancel(type)}>
            <FileExcelOutlined /> Cancel E-Ticket
          </p>
        </div>
      )}

      {type === "BuildPackage"
        ? ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
        : null} */}
      {/* <Modal
        title="User Invoice"
        visible={userInvoiceVisible}
        onOk={() => setUserinvoiceVisible(false)}
        onCancel={() => setUserinvoiceVisible(false)}
        width={"75%"}
      >
        <Invoice />
      </Modal> */}
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Email E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SMS E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={smsModalVisible}
        onOk={toggleSmsModal}
        onCancel={toggleSmsModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleSmsModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={smsForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form form={smsForm} onFinish={sendSms}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mobile No."
                name="Mobile"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Mobile Number Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  className="number-specing"
                  placeholder="Enter Mobile number"
                  autoComplete="off"
                  autoFocus
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default TicketSidebar;
